import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Card from '../components/card'

const BestWishesPage = () => {
  return (
    <Layout>
      <SEO title="Best Wishes" />
      <Content intro>
        <Card />
      </Content>
    </Layout>
  )
}

export default BestWishesPage

export const i18n = {
  en: '/best-wishes',
  // de: '/best-wishes',
}
