import React from 'react'
import styled from 'styled-components/macro'

import animationImg from '../../images/animation.gif'
import logoImg from '../../images/gasser-partner-logo-white-en.svg'

const Wrapper = styled.div`
  padding: 20px 0 80px 0;
  width: 680px;
  max-width: 100%;
  margin: 0 auto;

  @media (max-width: 740px) {
    padding-bottom: 48px;
  }
`

const Box = styled.div`
  border: 4px solid ${props => props.theme.colors.primary};

  @media (max-width: 740px) {
    border-width: 3px;
  }
`

const Logo = styled.div`
  background: ${props => props.theme.colors.primary};
  padding: 160px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 380px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

const LogoImg = styled.img`
  display: block;
  margin: 0 auto;
  width: 280px;
  height: auto;
  max-width: 100%;
`

const Main = styled.div`
  background: #fff;
  padding: 160px 20px;

  @media (max-width: 380px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

const Text = styled.div`
  width: 340px;
  max-width: 100%;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin: 0 auto;
`

const TextTitle = styled.div`
  font-size: 47px;
  line-height: 1.1;

  @media (max-width: 380px) {
    font-size: 40px;
  }
`

const TextSubline = styled.div`
  font-size: 32px;
  line-height: 1.4;

  @media (max-width: 380px) {
    font-size: 28px;
  }
`

const AnimationImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
`

function Card() {
  return (
    <Wrapper>
      <Box>
        <Main>
          <Text>
            <TextTitle>Best wishes</TextTitle>
            <TextSubline>
              for the Festive Season and a Happy New Year.
            </TextSubline>
          </Text>
        </Main>
        <AnimationImg src={animationImg} alt="" />

        <Logo>
          <a href="/">
            <LogoImg src={logoImg} alt="GASSER PARTNER ATTORNEYS AT LAW" />
          </a>
        </Logo>
      </Box>
    </Wrapper>
  )
}

export default Card
